@import "variables";
@import "framework/framework";
@import "../../node_modules/owl.carousel/dist/assets/owl.carousel.min";
@import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.min";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $color-black;
	font-family: $primary-font;
	width: 100%;
	font-size: 16px;
	line-height: 1;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
	width: 100%;
	overflow-x: hidden; 
	position: relative;
}

a {
	color: inherit;

	@include hover-focus {
		color: $color-gray;
	}
}

.page-wrap{
	position: relative;
	z-index: 10;
	min-height: 100vh; 
	display: flex;
	flex-direction: column;
	#main{
		flex: 1;
	}
}

#bgvideo{
    &-m, &-d{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        object-fit: cover;
        object-position: center;
        z-index: 0;
        text-shadow: 0 0 rems(10) #000A;
    }

    // &-m{
    //     @media screen and (min-width: 900px){
    //         display: none;
    //     }
    // }
    // &-d{
    //     @media screen and (max-width: 901px){
    //         display: none;
    //     } 
    // }
}

.container{
	max-width: rems(1800);
	padding: 0 rems(30);
}

.btn{
	padding: rems(10) rems(17);
	color: $color-white;
	background-color: $color-black;
	border: 2px solid $color-black;
	width: rems(160);
	display: inline-block;
	transition: .3s all;
	text-transform: uppercase;
	font-size: rems(12);
	em{
		display: inline-block;
	}

	i{
		font-size: rems(14);
	}

	@include hover-focus{
		background-color: $color-white;
		color: $color-black;
	}
	&.save{
		display: inline-flex;
		justify-content: space-between;
		&::after{
			content: '';
		}
	}
}

header {
	padding: rems(20) 0;
	.container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 rems(30);
		
		@media screen and (max-width: 680px){
			flex-direction: column;
			align-items: center;
		}
	}

	nav{
		display: flex;
		gap: rems(40);
		text-transform: uppercase;
	}
}

main {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	
	@media screen and (max-width: 680px){
		justify-content: center;
	}
	
	width: 100%;
	max-width: rems(1800);
	padding: 0 rems(30);
	align-self: center;

	.content{
		text-align: center;
		.btns{
			.btn{
				width: rems(240);
				margin: rems(5);
			}
		}
		p{
			width: 100%;
			margin: 1em auto;
			max-width: rems(450);
			font-size: rems(9);
			text-transform: uppercase;
		}
	}
}

footer {
	padding: rems(20) 0;
	.container{
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: 680px){
			flex-direction: column-reverse;
			align-items: center;
			.social{
				margin-bottom: rems(20);
			}
			.copyright{
				text-align: center;
			}
		}
	}
	.copyright{
		font-size: rems(8);
		width: 100%;
		max-width: rems(450);
		a{
			display: inline-block;
		}
	}

	.social{
		display: flex;
		font-size: rems(16);
		gap: 1em;
	}
	p{
		margin: 0;
	}
}


.lightbox{
	position: relative;
	width: rems(970);
	max-width: 100%;
	margin: rems(30) auto;
	padding: rems(30);
	background-color: $color-black;
	color: $color-white;
	text-transform: uppercase;
	text-align: center;
	.mfp-close{
		opacity: 1;
		color: $color-white;
		@include hover-focus{
			color: $color-gray;
		}
	}
	&#video{
		width: rems(700);
		padding: rems(40);
		.slider-video{
			.owl-prev, .owl-next{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				font-size: rems(24);
				z-index: 20;
			}
			.owl-prev{
				left: rems(-20);
			}
			.owl-next{
				right: rems(-20);
			}
		}
	}

	&.form{
		width: rems(450);

		input, label{
			display: block;
			text-align: center;
			margin: 0 auto rems(10);
		}
		input{
			width: 100%;
			background: none;
			color: $color-white;
			font-size: rems(12);
			border:none;
			border-bottom: 2px solid $color-white;
			&::placeholder{
				color: $color-white;
				text-transform: uppercase;
			}
		}

		label{
			font-size: rems(20);
			text-transform: uppercase;
		}
	}

	.btn{
		border-color: $color-white;
	}

	.slider-music{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.music-item{
			text-align: center;
			flex-basis: rems(300);
			padding: rems(10);
			.btn{
				margin-top: rems(10);
			}
		}
	}

	.slider-video{
		padding: 0;
		.item{
			width: 100%;
			.embed-container{
				background-color: $color-gray;
			}
		}
	}
}
